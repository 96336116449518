/* eslint-disable eqeqeq */
import { Flex, Image, } from "antd";
import React, { Component } from "react";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { LangEnum } from "../../constants/enums";
import { IMAGE } from "../../assets/images";
import { Header } from "antd/es/layout/layout";

type IProps = LocalizeContextProps & {
  lang: any,
  formatDatetime: any,
  onChangeLang: (newLang: string) => void;
  onChangeDatetime: (newDatetime: string) => void;
};

interface IState {

};

class AppHeader extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {

    }
  }


  render() {
    let { lang, onChangeLang, } = this.props;

    return (
      <React.Fragment>
        <Header className="app-header">
          <Flex wrap gap="small" justify="space-between" align="center" className="flex-header">
            <div className="collapsed-logo">
              <div className="logo">
                <Image loading='lazy' src={IMAGE.logo} preview={false} />
              </div>
            </div>
            <Flex gap={30} align="center">
              {lang == LangEnum.VI && (
                <div className="lang-group">
                  <Image loading='lazy' onClick={() => onChangeLang(LangEnum.EN)} preview={false} src={IMAGE.logoVi} />
                  <div><strong>VN</strong></div>
                </div>
              )}
              {lang == LangEnum.EN && (
                <div className="lang-group">
                  <Image loading='lazy' onClick={() => onChangeLang(LangEnum.VI)} preview={false} src={IMAGE.logoEn} />
                  <div><strong>EN</strong></div>
                </div>
              )}
            </Flex>
          </Flex>
        </Header>
      </React.Fragment>
    );
  }
}
export default withLocalize(AppHeader)
