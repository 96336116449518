import vi from "./vi.svg";
import en from "./en.svg";

const baseUrlCdn = 'https://smartbadminton.com/';

export const IMAGE = {
  logo: baseUrlCdn.concat("media/Logo.png"),
  logoVi: vi,
  logoEn: en,
}


