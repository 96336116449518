export const pathUrl = {
  HOME: "/",
  LOGIN: "/login",
  PAGE_500: "/page-500",
  PAGE_400: "/page-400",
  MASTER: "/master",
  BOOKING_SCHEDULE: {
    INDEX: "/booking-schedule",
    DETAIL: "/booking-schedule/detail",
  },
  SCHEDULE_TIMELINE: {
    INDEX: "/schedule-timeline",
    DETAIL: "/schedule-timeline/detail",
  },
  EMPTY: "",
};


