import React from "react";
import { pathUrl } from "./path-url";

// private
const home = React.lazy(() => import("../containers/home"));
const page500 = React.lazy(() => import("../containers/page-500"));
const page400 = React.lazy(() => import("../containers/page-400"));


const bookingSchedule = React.lazy(() => import("../containers/booking-schedule"));
const bookingScheduleDetail = React.lazy(() => import("../containers/booking-schedule/detail"));
const scheduleTimeline = React.lazy(() => import("../containers/schedule-timeline"));
const scheduleTimelineDetail = React.lazy(() => import("../containers/schedule-timeline/detail"));

export interface IRouter {
  path: string;
  title: string;
  component: any;
}

export const pageRouter: IRouter[] = [
  {
    path: pathUrl.HOME,
    title: "home",
    component: home,
  },
  {
    path: pathUrl.PAGE_500,
    title: "Page 500",
    component: page500,
  },
  {
    path: pathUrl.PAGE_400,
    title: "Page 400",
    component: page400,
  },
  {
    path: pathUrl.BOOKING_SCHEDULE.INDEX,
    title: "booking schedule",
    component: bookingSchedule,
  },

  {
    path: pathUrl.BOOKING_SCHEDULE.DETAIL,
    title: "booking schedule detail",
    component: bookingScheduleDetail,
  },

  {
    path: pathUrl.SCHEDULE_TIMELINE.INDEX,
    title: "schedule timeline",
    component: scheduleTimeline,
  },

  {
    path: pathUrl.SCHEDULE_TIMELINE.DETAIL,
    title: "schedule timeline detail",
    component: scheduleTimelineDetail,
  },

];




